#ArticlesPage {
	display: flex;
	flex-direction: column;

	#ArticlesSection {
		display: flex;
		flex-direction: column;
		padding: 20px;
		background-color: #EFF4FC;	
		margin-bottom: 10px;
	
		#ArticlesSectionHeading {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			h2 {
				color: #20458C;
				margin-top: 0;
				margin-bottom: 10px;
			}

			a {
				font-weight: bold;
				text-decoration: none;
				color: #20458C;
			}
		}

		#ListOfArticlesSection {
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
		}

		#MoreButton {
			margin-top: 10px;
			svg {
				transform: scale(1.25);
			}
		}
	}
}