#QuizAnswer {
	display: flex;
	flex-direction: column;
	padding: 20px;
	background-color: #EFF4FC;	
	margin-bottom: 10px;
	text-align: left;

	.AnswerSectionHeading {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		vertical-align: center;
		margin-bottom: 20px;

		h2 {
			color: #20458C;
			margin: 0;
		}

		.correct {
			display: flex;
			flex-direction: row;
			align-items: center;
			color: green;

			svg {
				transform: scale(1.3);
			}

			h2 {
				color: green;
			}
		}

		.incorrect {
			display: flex;
			flex-direction: row;
			align-items: center;
			color: red;

			svg {
				transform: scale(1.3);
			}

			h2 {
				color: red;
			}
		}
	}

	.AnswerText {
		font-size: 16px;
		margin-top: 0;
	}

	.linksSection {
		display: flex;
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;
	}
}