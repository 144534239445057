#QuizQuestion {
	display: flex;
	flex-direction: column;
	padding: 20px;
	background-color: #EFF4FC;	
	margin-bottom: 10px;
	text-align: left;

	.QuestionSectionHeading {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		vertical-align: center;
		margin-bottom: 20px;

		h2 {
			color: #20458C;
			margin: 0;
		}
	}

	.QuestionContent {
		display: flex;
		flex-direction: row;
		justify-content: center;

		@media (max-width: 715px) {
	        flex-direction: column;
	    }

		img {
			flex: 1;
			max-width: 50%;

			@media (max-width: 715px) {
		        max-width: 100%;
		        margin-bottom: 10px;
		    }
		}

		div {
			flex: 1;
			margin-left: 20px;

			@media (max-width: 715px) {
		        margin-left: 0px;
		    }

			.QuestionText {
				font-size: 16px;
				margin-top: 0;
			}

			.MuiFormControl-root {
				margin: 0;

				#QuestionOptionsLabel {
					font-size: 16px;
				}
			}
		}
	}
}