#Header {
    #headerToolbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0;

        button {
            color: #20458C;
        }
    }
}