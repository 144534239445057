#QuizTile {
	margin: 5px;
	min-width: 250px;
	max-width: 250px;

	.MuiCardContent-root {
		height: 63px;
	}

	h4 {
		font-size: 18px;
		font-weight: bold;
		color: black;
		margin: 0;
	}

	h5 {
		font-size: 16px;
		font-weight: normal;
		font-style: italic;
		color: black;
		margin: 0;
	}

	// button {
	// 	padding: 10px;
	// }

}