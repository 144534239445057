#SearchResultsPage {
	display: flex;
	flex-direction: column;

	#SearchResultsSection {
		display: flex;
		flex-direction: column;
		padding: 20px;
		background-color: #EFF4FC;	
		margin-bottom: 10px;
	
		#SearchResultsSectionHeading {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 10px;

			@media (max-width: 715px) {
		        flex-direction: column;
		        justify-content: center;
		    }

			h2 {
				color: #20458C;
				margin: 0;
				margin-right: 50px;

				@media (max-width: 715px) {
			        margin: 0;
			    }
			}
		}

		#ListOfSearchResultsSection {
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
		}

		#MoreButton {
			margin-top: 10px;
			svg {
				transform: scale(1.25);
			}
		}
	}
}