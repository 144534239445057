#Footer {
    #footerToolbar {
        justify-content: flex-end;

        a, a:visited {
            margin-left: 20px;
            text-decoration: none;
            color: #20458C;
        }
    }
}