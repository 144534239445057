#PrivacyPage {
	display: flex;
	flex-direction: column;
	padding-bottom: 30px;
	background-color: #EFF4FC;

	h1 {
		color: #20458C;
	}

	#textSection {
		flex: 3;
		margin: 0;
		padding: 20px;
		padding-top: 0;
		text-align: left;
	}
}