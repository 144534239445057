#AboutPage {
	display: flex;
	flex-direction: column;
	padding-bottom: 50px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: #EFF4FC;

	h1 {
		color: #20458C;
	}

	#AboutContent {
		display: flex;
		flex-direction: row;
		padding-left: 50px;
		padding-right: 50px;

		@media (max-width: 715px) {
	        flex-direction: column;
	        padding-left: 20px;
	        padding-right: 20px;
	    }

		#textSection {
			flex: 3;
			margin: 0;
			padding: 20px;
			text-align: left;

			p {
				margin-top: 0;
			}

			@media (max-width: 715px) {
		        padding: 0;
		    }
		}

		#imageSection {
			flex-direction: column;
			align-items: center;
  			justify-content: flex-start;
  			text-align: center;
  			flex: 2;
  			padding: 20px;
		}
	}

}