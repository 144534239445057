#Banner {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 10px;

	img {
		height: 150px;
	}

	div {
		display: flex;
		flex-direction: column;
		text-align: left;
		margin-left: 20px;
		justify-content: center;

		h1 {
			color: #20458C;
			font-size: 48px;
			margin: 0;

			@media (max-width: 715px) {
				font-size: 36px;
		    }
		}

		h2 {
			color: #20458C;
			font-size: 24px;
			font-weight: normal;
			font-style: italic;
			margin: 0;

			@media (max-width: 715px) {
				font-size: 20px;
		    }
		}
	}
}