#SubscriptionBar {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 50px;
	background-color: #20458C;
	text-align: left;
	margin-top: 10px;
	margin-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;

	p {
		color: white;
		font-weight: bold;
	}

	button {
		height: 39px;
	}
}

#SubscriptionDialog {
	#SubscriptionDialogTitle {
		color: #20458C;
		font-weight: bold;
		text-align: center;
	}
}