#LinkTile {
	margin: 5px;
	width: 250px;
	
	h4 {
		font-size: 16px;
		font-weight: bold;
		color: black;
		margin: 0;
		text-align: center;
	}

	@media (max-width: 840px) {
		width: 100%;
		button {
			display: flex;
			height: 60px;

			img {
				width: 20%;
			}

			.MuiCardContent-root {
				flex: 1;
			}
		}

		h4 {
			text-align: left;
		}
    }
}