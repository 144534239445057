#QuizPage {
	display: flex;
	flex-direction: column;

	h1 {
		color: #20458C;
		text-align: left;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	hr {
		border: none;
		border-top: 3px solid #20458C;
		width: 100%;
		margin-top: 0;
		margin-bottom: 10px;
	}

	#QuizResultsSection {
		display: flex;
		flex-direction: column;
		padding: 20px;
		padding-top: 20px;
		padding-bottom: 20px;
		background-color: #EFF4FC;

		h1 {
			color: #20458C;
			margin: 0;
		}

		p {
			text-align: left;
		}

		#linksSection {
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
		}
	}
}