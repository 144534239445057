.App {
    -webkit-text-size-adjust: 100%;
    min-height: 100vh;
    background: linear-gradient(180deg, #B4D1FF 13.53%, #FFFFFF 100%);
    text-align: center;
    display: flex;
    flex-direction: column;
    
    @media (max-width: 1024px) {
        padding-left: 10px;
        padding-right: 10px;
    }

    @media (min-width: 1024px) {
        padding-left: 10%;
        padding-right: 10%;
    }

    #content {
        flex: 1,
    }
}

.webOnly {
    @media (max-width: 715px) {
        display: none;
    }
}

.mobileOnly {
    @media (min-width: 716px) {
        display: none;
    }
}

@import './components/header.scss';
@import './components/footer.scss';
@import './components/banner.scss';
@import './components/subscriptionBar.scss';
@import './components/quizTile.scss';
@import './components/quizQuestion.scss';
@import './components/quizAnswer.scss';
@import './components/articleTile.scss';
@import './components/linkTile.scss';

@import './pages/aboutPage.scss';
@import './pages/homePage.scss';
@import './pages/quizzesPage.scss';
@import './pages/quizPage.scss';
@import './pages/articlesPage.scss';
@import './pages/articlePage.scss';
@import './pages/searchResultsPage.scss';
@import './pages/termsOfUsePage.scss';
@import './pages/privacyPage.scss';
@import './pages/noMatchPage.scss';