#ArticlePage {
	#ArticleSection {
		display: flex;
		flex-direction: column;
		padding: 50px;
		padding-top: 20px;
		background-color: #EFF4FC;
		margin-bottom: 10px;

		@media (max-width: 715px) {
	        padding-left: 5%;
	        padding-right: 5%;
	    }

		h1 {
			color: #20458C;
			margin: 0;
		}

		h3 {
			font-size: 18px;
		}

		h4 {
			font-weight: normal;
			font-style: italic;
			font-size: 16px;
			margin: 0;
		}

		#ArticleContent {
			display: flex;
			flex-direction: row;

			#articleTextSection {
				flex: 3;
				margin: 0;
				text-align: left;

				img {
					width: 80%;
					display: block;
					margin: 0 auto;

					@media (max-width: 715px) {
				        width: 100%;
				    }
				}
			}

			#imageSection {
				display: flex;
				align-items: center;
	  			justify-content: center;
	  			text-align: center;
	  			flex: 2;
	  			padding: 20px;
			}
		}

		#imageSection {
			img {
				width: 80%;

				@media (max-width: 715px) {
			        width: 100%;
			    }
			}
		}
	}

	#SeeMoreSection {
		display: flex;
		flex-direction: column;
		padding: 50px;
		padding-top: 20px;
		background-color: #EFF4FC;

		@media (max-width: 715px) {
	        padding-left: 5%;
	        padding-right: 5%;
	    }

		h1 {
			color: #20458C;
			margin: 0;
		}

		p {
			text-align: left;
		}
		
		#linksSection {
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
		}
	}
}